<!--
 * @Author: your name
 * @Date: 2020-10-27 15:36:16
 * @LastEditTime: 2020-12-30 20:23:17
 * @LastEditors: Please set LastEditors
 * @Description: 我的反馈
 * @FilePath: /netHallOfficialAccounts/src/views/Feedback/MyFeedBack.vue
-->
<template>
  <div class="myFeedBack-page">
    <div class="myFeedBack-header">
      <van-tabs v-model="backStatus" @click="onClick" color="#1677FF" title-active-color="#1677FF">
        <van-tab title="全部" name=""></van-tab>
        <van-tab
          v-for="(item, index) in dictoryBycode_STATUS_CODE"
          :key="index"
          :title="item.name"
          :name="item.code"
        ></van-tab>
      </van-tabs>
    </div>
    <div :class="selectedFlag ? 'myFeedBack-select' : 'myFeedBack-selected'">
      <van-dropdown-menu active-color="#1677FF" :close-on-click-overlay="false">
        <van-dropdown-item :title="titleValue" ref="item" style="color: red">
          <drop-down-selection
            :currentValue="currentIndex"
            :dataValArr="feedbackTerms"
            @getSelectValue="getSelectValueInfo"
          />
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>

    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :disabled="true"
      v-if="dataInfoFlag"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="selectFeedbackHandling"
      >
        <div class="myFeedBack-content">
          <ul>
            <li v-for="(item, index) in dataInfo" :key="index">
              <div class="title-li">
                <div class="left-txt">
                  <i>
                    <img
                      :src="
                        item.status === 'nh_00'
                          ? handle_First
                          : item.status === 'nh_01'
                          ? handle_Second
                          : item.status === 'nh_02'
                          ? handle_Third
                          : item.status === 'nh_03'
                          ? handle_Fourth
                          : ''
                      "
                      alt=""
                    />
                  </i>
                  <span>{{
                    item.status === 'nh_00'
                      ? '办理中'
                      : item.status === 'nh_01'
                      ? '已办结'
                      : item.status === 'nh_02'
                      ? '已拒绝'
                      : item.status === 'nh_03'
                      ? '已取消'
                      : ''
                  }}</span>
                </div>
                <em>{{ item.createdTime }}</em>
              </div>
              <div class="content-li">
                <p class="title">
                  {{
                    item.feedbackCode === '0101'
                      ? '违章举报'
                      : item.feedbackCode === '0102'
                      ? '意见反馈'
                      : item.feedbackCode === '0103'
                      ? '报事报修'
                      : ''
                  }}
                </p>
                <p class="van-ellipsis">内容: {{ item.content }}</p>
                <p class="van-ellipsis">地址: {{ isaddress(item.address) }}</p>
              </div>
              <div class="detail-li" @click="gotoDetailPage(item)">
                <span>查看详情</span>
                <van-icon name="arrow" />
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>

    <no-data v-else />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import NoData from '@/components/noData';
import DropDownSelection from '@/components/dropDownSelection';
import { formatTime } from '@/utils/tools';
import { detailsRoute } from '@/utils/businessRoute';

import handle_First from '../../assets/icon/ic_blue_icon@2x.png';
import handle_Third from '../../assets/icon/ic_red_refuse@2x.png';
import handle_Second from '../../assets/icon/ic_green_icon@2x.png';
import handle_Fourth from '../../assets/icon/ic_grey_icon@2x.png';
import handle_Five from '../../assets/icon/待预审@2x.png';

import { selectBusCode, selectFeedbackHandling } from '../../api/home';

export default {
  name: 'MyFeedBack',
  components: {
    NoData,
    DropDownSelection,
  },
  data() {
    return {
      titleValue: '反馈类型筛选',
      backStatus: '',
      tabKeyValueList: [],
      tabAllList: [], // tab全部code结合
      typeAllList: [], // type...
      handle_First,
      handle_Third,
      handle_Second,
      handle_Fourth,
      handle_Five,
      dataInfo: [],
      dataInfoFlag: true,
      currentIndex: 0,
      size: 5,
      currentPage: 1,
      dataTotal: 0,
      refreshing: false,
      loading: false,
      finished: false,
      selectedFlag: true, // 反馈类型筛选的颜色控制
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.query && from.query.backStatus) {
        vm.backStatus = from.query.backStatus;
        vm.tabAllList =
          vm.backStatus === 'all'
            ? vm.dictoryBycode_STATUS_CODE.map((item) => {
                return item.code;
              })
            : [vm.backStatus];
        vm.currentIndex = Number(from.query.currentIndex);
        vm.titleValue =
          from.query.isSelected == 'true' ? vm.feedbackTerms[vm.currentIndex].name : '反馈类型筛选';
        vm.selectedFlag = from.query.isSelected == 'true' ? false : true;
      }
    });
  },

  computed: {
    ...mapState('dict', {
      dictory: (state) => state.dictory,
      Feedback: (state) => state.Feedback,
      dictoryBycode_STATUS_CODE: (state) => state.dictoryBycode_STATUS_CODE,
      // dictoryBycode_BUS_CODE: (state) => state.dictoryBycode_BUS_CODE,
    }),
    isaddress() {
      return function(item) {
        if (item) {
          let str = '';
          try {
            let data = JSON.parse(item);
            str = data.areaInfor + '-' + data.fullAddressInfor;
          } catch (error) {
            str = item;
          }
          return str;
        }
      };
    },
    feedbackTerms() {
      // if (this.dictoryBycode_BUS_CODE) {
      // let data = this.dictoryBycode_BUS_CODE;
      // let item = [{code: '', name: '全部类型'}]
      if (this.dictory && this.Feedback) {
        let data = this.dictory && this.dictory[this.Feedback] && this.dictory[this.Feedback].child;
        let item = [{ child: [], code: '', name: '全部类型', value: '' }];
        let newData = item.concat(data);
        data &&
          data.map((item, index) => {
            this.typeAllList.push(item.code);
          });
        return newData;
      }
      return null;
    },
  },
  mounted() {
    // 获取状态类型
    this.tabAllList = [];
    this.dictoryBycode_STATUS_CODE.map((item) => {
      this.tabAllList.push(item.code);
    });
  },
  methods: {
    // 获取页面数据
    async selectFeedbackHandling() {
      let arg = {
        statusCodeList: this.tabAllList,
        busTypeCodeList:
          this.currentIndex === 0
            ? this.typeAllList
            : this.typeAllList.slice(this.currentIndex - 1, this.currentIndex),
        page: {
          current: this.currentPage,
          size: this.size,
        },
      };
      const { status, resultData } = await selectFeedbackHandling(arg);
      if (status === 'complete') {
        if (resultData.records.length > 0) {
          this.dataInfoFlag = true;
          resultData.records.map((item) => {
            item.createdTime = formatTime(item.createdTime);
          });
          // 分页
          this.dataTotal = resultData.total;
          this.dataInfo = this.dataInfo.concat(resultData.records);
          this.currentPage++;
          // 没有更多数据
          if (this.dataInfo.length >= Number(this.dataTotal)) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        } else {
          this.dataInfo = [];
          this.dataInfoFlag = false;
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    // tab切换
    onClick(name, title) {
      if (name === '') {
        this.tabAllList = [];
        this.dictoryBycode_STATUS_CODE.map((item) => {
          this.tabAllList.push(item.code);
        });
      } else {
        this.tabAllList = [];
        this.tabAllList = [name];
      }

      this.currentPage = 1;
      this.dataInfo = [];
      this.dataTotal = 0;
      // this.finished = false;
      this.selectFeedbackHandling();
    },
    // 选择类型
    getSelectValueInfo(value, index) {
      this.currentIndex = index;
      this.titleValue = value;
      this.$refs.item.toggle();
      this.currentPage = 1;
      this.dataInfo = [];
      this.selectedFlag = false;
      // this.finished = false;
      this.selectFeedbackHandling();
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1;
      this.dataInfo = [];
      this.typeAllList = [];
      this.tabAllList = [];
      this.finished = false;
      this.selectFeedbackHandling();
      this.loading = false;
    },
    // 查看详情
    gotoDetailPage(data) {
      let pathDetail = detailsRoute(data.feedbackCode);
      this.$router.push({
        path: pathDetail,
        query: {
          state: 'see',
          id: data.feedbackHandlingId,
          backStatus: this.backStatus === '' ? 'all' : this.backStatus,
          currentIndex: this.currentIndex,
          isSelected: this.titleValue == '反馈类型筛选' ? false : true,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myFeedBack-page {
  .myFeedBack-header {
    /deep/.van-tab {
      padding: 0 10px;
    }
  }
  .myFeedBack-select {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65);
    }
    // /deep/.van-dropdown-menu__title::after {
    //   border-color: transparent transparent #555555 #555555;
    // }
    /deep/.van-dropdown-menu__item {
      margin-right: 10px;
    }
  }
  // 选中的样式
  .myFeedBack-selected {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: #1677ff;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent #1677ff #1677ff;
    }
  }
  .myFeedBack-content {
    ul {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 10px;
      li {
        margin-bottom: 16px;
        background: #ffffff;
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 16px;
        .title-li {
          display: flex;
          justify-content: space-between;
          height: 48px;
          line-height: 48px;
          padding: 0;
          // border-bottom: 1px solid rgba(0,0,0,0.08);
          position: relative;
          i {
            font-style: normal;
            padding-right: 8px;
            img {
              width: 18px;
              height: 18px;
              vertical-align: middle;
            }
          }
          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            text-align: center;
          }
          em {
            font-style: normal;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            text-align: center;
          }
        }
        .title-li::after {
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 0px;
          bottom: 0;
          left: 0px;
          border-bottom: 1px solid #ebedf0;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
        }
        .content-li {
          // height: 52px;
          // line-height: 52px;
          // display: flex;
          // justify-content: space-between;
          // border-bottom: 1px solid rgba(0,0,0,0.08);
          position: relative;
          padding-top: 14px;
          padding-bottom: 15px;
          p.title {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 700;
            display: block;
          }
          p {
            margin: 0px;
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
          }
        }
        .content-li::after {
          position: absolute;
          box-sizing: border-box;
          content: ' ';
          pointer-events: none;
          right: 0px;
          bottom: 0;
          left: 0px;
          border-bottom: 1px solid #ebedf0;
          -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
        }
        .detail-li {
          height: 49px;
          line-height: 49px;
          display: flex;
          justify-content: space-between;
          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
          }
          /deep/.van-icon {
            line-height: 49px;
            color: #969799;
          }
        }
      }
    }
  }
}
</style>
